import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { DefaultHeaderAnnouncementBanner } from './HeaderAnnouncementBanner';
import HeaderNavigation from './HeaderNavigation';
import HeaderNavigationCategoryOverlay from './HeaderNavigationCategoryOverlay';
import { RegionKey } from './HeaderNavigationRegionSelect';
import useHeaderHelpers, {
  HeaderNavigationCategory
} from './Shared/useHeaderHelpers';
import { useRouter } from 'next/router';
import menuImageStore from 'zus/menuImageStore';

interface CategoryOverlayOptions {
  visible: boolean;
  activeCategory?: HeaderNavigationCategory;
}
/* eslint-disable no-unused-vars */
export interface HeaderProps {
  showAuthModal: (type: 'SIGN_IN' | 'SIGN_UP', redirectPath?: string) => void;
  updateRegion: (region: RegionKey) => void;
  isBasketReady: boolean;
  toggleBasketOverlay: () => void;
  basketItemCount: number | string;
}
/* eslint-enable no-unused-vars */

const Header = ({
  showAuthModal,
  updateRegion,
  isBasketReady,
  toggleBasketOverlay,
  basketItemCount
}: HeaderProps) => {
  const [categoryOverlayOptions, setCategoryOverlayOptions] =
    useState<CategoryOverlayOptions>({
      visible: false,
      activeCategory: undefined
    });

  const updateActiveCategory = (payload: HeaderNavigationCategory) => {
    setCategoryOverlayOptions({
      ...categoryOverlayOptions,
      activeCategory: payload,
      visible: true
    });
  };

  const updateOverlayVisibility = (value: boolean) => {
    setCategoryOverlayOptions({
      ...categoryOverlayOptions,
      visible: value
    });
  };
  
  const router = useRouter();

  useEffect(() => {
    menuImageStore.getState().fetchImages(router.locale);
  }, [router.locale, router.asPath]);

  const activeCategoryName = useMemo(() => {
    if (!categoryOverlayOptions.visible) return;
    return categoryOverlayOptions.activeCategory?.name;
  }, [categoryOverlayOptions]);

  const [isSticky, setIsSticky] = useState(false);

  const {
    scrollDirection,
    scrollPosY,
    categoryList,
    menuActionClickHandler,
    loggedUser
  } = useHeaderHelpers({
    showAuthModal
  });

  useEffect(() => {
    if (!window || window?.innerWidth < 1024) return;

    setIsSticky(
      (scrollPosY as number) > 0 &&
      (scrollDirection === 'UP' || categoryOverlayOptions.visible)
    );
  }, [scrollDirection, scrollPosY, categoryOverlayOptions]);

  return (
    <>
      {isSticky && categoryOverlayOptions.visible && (
        <div className="h-0 lg:h-[121px] 2xl:h-[136px]"></div>
      )}
      <div
        className={cx(
          'left-0 z-50 hidden w-full transition-all duration-[750ms] lg:block',
          {
            'top-0 shadow-card': isSticky && !categoryOverlayOptions.visible,
            'top-[-140px]': !isSticky,
            fixed: categoryOverlayOptions.visible && isSticky,
            sticky: !categoryOverlayOptions.visible
          }
        )}>
        <DefaultHeaderAnnouncementBanner />
        <HeaderNavigation
          activeCategoryName={activeCategoryName}
          updateActiveCategory={updateActiveCategory}
          categoryList={categoryList}
          onMenuActionClicked={menuActionClickHandler}
          loggedUser={loggedUser}
          updateRegion={updateRegion}
          basketReady={isBasketReady}
          toggleBasketOverlay={toggleBasketOverlay}
          basketItemCount={basketItemCount}
        />
      </div>
      <HeaderNavigationCategoryOverlay
        {...categoryOverlayOptions}
        updateOverlayVisibility={updateOverlayVisibility}
      />
    </>
  );
};

export default Header;
