import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Logo from 'components/Icons/Logo';
import HeaderBookingButton from './HeaderBookingButton';
import HeaderNavigationBasket from './HeaderNavigationBasket';
import HeaderNavigationRegionSelect, {
  RegionKey
} from './HeaderNavigationRegionSelect';
import HeaderNavigationUser from './HeaderNavigationUser';
import {
  HeaderNavigationCategory,
  MenuActionClickHandler
} from './Shared/useHeaderHelpers'; 

  
/* eslint-disable no-unused-vars */
export type UpdateActiveCategoryHandler = (
  activeCategory: HeaderNavigationCategory
) => void;
export type UpdateRegion = (region: RegionKey) => void;
/* eslint-enable no-unused-vars */

export type HeaderNavigationLoggedUser = {
  firstName: string;
};

interface HeaderNavigationProps {
  basketReady?: boolean;
  basketItemCount?: number | string;
  loggedUser?: HeaderNavigationLoggedUser;
  activeCategoryName?: string;
  categoryList: HeaderNavigationCategory[];
  updateActiveCategory: UpdateActiveCategoryHandler;
  updateRegion: UpdateRegion;
  onMenuActionClicked: MenuActionClickHandler;
  toggleBasketOverlay: () => void;
}
const HeaderNavigation: FC<HeaderNavigationProps> = ({
  basketItemCount,
  updateActiveCategory,
  activeCategoryName,
  categoryList,
  updateRegion,
  onMenuActionClicked,
  loggedUser,
  basketReady = false,
  toggleBasketOverlay
}) => {
  const setActiveCategory = (category: HeaderNavigationCategory) => {
    updateActiveCategory({
      name: category.name,
      items: category.items,
      bannerImage: category.bannerImage,
      sections: category?.sections
    });
  };


  return (
    <div className="relative bg-white">
      <div className="mx-auto flex h-[75px] max-w-[1224px] px-4 text-brand-black100 2xl:h-[90px]">
        <div className="flex flex-shrink-0 items-center">
          <Link prefetch={false} href="/" aria-label="Go to Dresden Vision Home Page">

            <Logo fill="currentColor" height={26} width={153} />

          </Link>
        </div>
        <ul
          className="mx-[30px] flex h-full w-full max-w-[500px] flex-auto justify-between text-[14px] shop:mx-[30px] shop:max-w-[610px] shop:text-[16px] xl:mx-[40px]"
          data-header-navigation-list>
          {categoryList.map((category, idx) => (
            <li
              key={category.name}
              onMouseOver={() => setActiveCategory(category)}
              onMouseEnter={() => setActiveCategory(category)}
              onMouseMove={() => setActiveCategory(category) }
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setActiveCategory(category);
              }}
              style={{
                order:(idx+1)
              }}
              className={`flex cursor-pointer items-center border-b-[3px] font-medium transition-colors duration-500 ${
                activeCategoryName === category.name
                  ? 'border-brand-orange text-brand-orange'
                  : 'border-transparent text-brand-black100'
              }`}>
              {category.name}
            </li>
          ))}
          <li
            className="flex h-full items-center pb-[3px] 2xl:min-w-[154px] order-10"
            data-ignore-category-overlay>
            <HeaderBookingButton />
          </li>
        </ul>
        <div className="flex flex-auto flex-shrink-0 justify-end">
          <HeaderNavigationUser
            onMenuActionClicked={onMenuActionClicked}
            loggedUser={loggedUser}
          />
          <HeaderNavigationBasket
            ready={basketReady}
            itemCount={basketItemCount}
            onBasketClick={toggleBasketOverlay}
            className="mx-[30px]"
          />
          <HeaderNavigationRegionSelect updateRegion={updateRegion} />
        </div>
      </div>
    </div>
  );
};

export default HeaderNavigation;
