import { create } from 'zustand'



// Define a type for the store's state
type CategoryImageMap = Map<string, string>; // Mapping slug to imageURL

type CategoryImageStore = {
  images: CategoryImageMap;
  // eslint-disable-next-line no-unused-vars
  fetchImages: (locale: string) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  getImageUrlBySlug: (slug: string) => string | undefined;
};

// Create the store
const menuImageStore: any = create<CategoryImageStore>((set) => ({
  images: new Map(),
  fetchImages: async (locale: string) => {
    const response = await fetch(`/api/menuimage?locale=${locale}`, { next: { revalidate: (process.env.NEXT_PUBLIC_ENV === 'production' ? 1800 : 60) } });
    const data = await response.json();

    // Assuming data is an array of objects with slug and url properties
    const newImages = new Map<string, string>();
    data.forEach((item: { slug: string; featuredImage: string }) => {
      if (!newImages.has(item.slug)) {
        // If not, add the slug and url to the map
        newImages.set(item.slug, item.featuredImage);
      }
    });
    //console.log('newImages', newImages);
    set({ images: newImages });
  },
  getImageUrlBySlug: (slug: string) => {
    const state = menuImageStore.getState();
    return state.images.get(slug);
  },
}));

export default menuImageStore;
